.AiSuccess {
    background-color: #0B0B0B;
    padding: 150px 0;
    position: relative;
    overflow: hidden;
    @media (max-width: 992px) {
      padding: 80px 0;
    }
    .title{
      text-align: left;
      max-width: 570px;
      position: relative;
      @media (max-width: 1000px) {
        width: 100%;
        margin-bottom: 20px;
      }
      h2{
        font-size: 60px;
        font-weight: 700;
        color:#fff;
        margin-bottom: 50px;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 80px;
          height: 5px;
          background-color: #DCA629;
        }
        @media (max-width: 1600px) {
          max-width: 500px;
          font-size: 44px;
        }
        @media (max-width: 993px) {
          max-width: 100%;
        }
        span{
          color: #DCA629;
        }
      }
      p{
        font-size: 24px;
        color: rgba(255, 255, 255, 0.7);
        margin: 20px 0;
        @media screen and (max-width: 600px){
          font-size: 18px;
          
        }
      }
      .tagline{
        color: #DCA629;
        margin-bottom: 10px;
      }
      .btn-custom{
        margin-top: 20px;
        display: inline-block;
    
      }
    }
    .ai-success.block{
        padding: 0 20px;
        @media only screen and (max-width:993px){
          margin-bottom: 50px;
          padding: 40px 0;
        }
        .col-6:nth-child(1){
          .experties{
            border-bottom-right-radius: 0px;
          }
        }
        .col-6:nth-child(2){
          .experties{
            border-bottom-left-radius: 0px;
          }
        }
        .col-6:nth-child(3){
          .experties{
            border-top-right-radius: 0px;
          }
        }
        .col-6:nth-child(4){
          .experties{
            border-top-left-radius: 0px;
          }
        }
        .experties {
            padding: 60px 0px;
            text-align: center;
            background-color: #DCA629;
            align-items: center;
            border-radius: 50px ;
            @media (max-width: 993px) {
              padding: 50px 0;
            }
            @media (max-width: 500px) {
              padding: 30px 0;
            }
            h4 {
              margin: 0;
              font-size: 60px;
              color: #521212;
              font-weight: 600;
              white-space: pre;
              font-family: 'Sf Pro Display';
              @media screen and (max-width: 992px){
                font-size: 44px;
              }
             
            }
            p{
                font-size: 18px;
                font-weight: 600;
            }
          }

          .row{
            .col-6{
                .experties{
                    &:nth-of-type(2){
                        border-radius: 50%;
                        background-color: #521212 !important;
                    }
                }
            }
        }
    }
  }