.workWithUs{
    padding: 100px 0;
    overflow: hidden;
    @media screen and (max-width: 768px){
      padding: 0 0 50px;
    }
    .block{
        background: rgba(221, 167, 42, 1);
        border-radius: 1000px;
        padding: 10px;
        display: flex;
        align-items: center;
        img{
            width: 300px;
            height: 300px;
            @media screen and (max-width: 600px){
                width: 80px;
                height: 80px;
            }
        }
        .content{
            width: 100%;
            text-align: center;
            max-width: 558px;
            margin: 0 auto;
            h3{
                font-size: 45px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
                margin-bottom: 10px;
                @media screen and (max-width: 992px){
                    font-size: 40px;
                }
                @media screen and (max-width: 600px){
                    font-size: 22px; 
                    margin-bottom: 0;
                }
            }
            p{
                font-size: 40px;
                color: rgba(0, 0, 0, 1);
                margin-bottom: 0;
                line-height: normal;
                @media screen and (max-width: 992px){
                    font-size: 30px;
                }
                @media screen and (max-width: 600px){
                    font-size: 16px; 
                }
            }
        }
    }
}