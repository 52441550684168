.chooseUs{
    padding: 150px 0;
    overflow: hidden;
    @media screen and (max-width: 992px){
        padding: 100px 0;
    }
    @media screen and (max-width: 767px){
        padding: 80px 0;
    }
    .content{
        h2{
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 40px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            @media screen and (max-width: 767px){
                margin-top: 40px;
            }
            span{
                color: rgba(220, 166, 41, 1);
            }
        }
        p{
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 0;
            margin-top: 30px;
            color: rgba(255, 255, 255, 0.7);
            @media screen and (max-width: 992px){
                font-size: 18px;
                line-height: 28px;
            }
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .chooseList{
        padding-top: 60px;
        @media screen and (max-width: 767px){
            padding-top: 30px;
        }
        .block{
            background-color: #222222;
            border-radius: 20px;
            padding: 20px;
            display: flex;
            height: 100%;
            align-items: center;
            img{
                width: 45px;
                height: 45px;
                margin-right: 10px;
            }
            p{
                margin: 0;
                font-size: 22px;
                color: #fff;
                line-height: 32px;
                @media screen and (max-width: 767px){
                    font-size: 18px;
                }
            }
        }
    }
}