.custom-solutions{
    padding: 150px 0 ;
    overflow: hidden;
    @media screen and (max-width: 992px){
        padding: 100px 0 ;
    }
    @media screen and (max-width: 767px){
      padding: 50px 0 ;
   }
    .title {
        max-width: 950px;
        text-align: center;
        margin: 0 auto;
    
        h2{
          font-size: 60px;
          font-weight: 700;
          color:#fff;
          margin-bottom: 10px;
          @media (max-width: 1600px) {
            font-size: 44px;
          }
          span{
            color: #DCA629;
          }
        }
      }
      .block{
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            li{
                margin: 100px 0 0;
                width: 18.666%;
                text-align: center;
                position: relative;
                @media screen and (max-width: 600px){
                    width: 33.33%;
                  }
                
                  
                  
                @media screen and (max-width: 992px) {
                    margin: 70px 0 0;
                  }
                img{
                    // width: auto;
                    height: 100px;
                    object-fit: contain;
                    @media screen and (max-width: 992px){
                        width: auto;
                        height: 80px;
                    }
                }
            }
        }
      }
}