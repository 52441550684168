.brandingIdentity{
padding: 150px 0;
background: #0B0B0B;
position: relative;
overflow: hidden;
@media screen and (max-width: 992px){
    padding: 100px 0;
}
@media screen and (max-width: 767px){
    padding: 80px 0;
}
.title {
    text-align: center;

    h2{
      font-size: 60px;
      font-weight: 700;
      color:#fff;
      margin-bottom: 80px;
      @media (max-width: 992px) {
        font-size: 44px;
        margin-bottom: 40px;
      }
      span{
        color: rgba(220, 166, 41, 1);
        display: block;
      }
    }
  }
  .brandIdentity{
    img{
        width: 100%;
        height: auto;
    }
  }
}