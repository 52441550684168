.ecommerceFeatures{
    padding: 150px 0;
    background: #222222;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &::after{
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2168.94px;
        height: 100%;
        background: url(../../../../../../public/images/services/ecommerceFeatures.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }
    @media screen and (max-width: 992px){
        padding: 100px 0;
    }
    @media screen and (max-width: 767px){
        padding: 80px 0;
    }
    .title {
        text-align: center;
    
        h2{
          font-size: 60px;
          font-weight: 700;
          color:#fff;
          margin-bottom: 30px;
          @media (max-width: 992px) {
            font-size: 44px;
          }
          span{
            color: rgba(220, 166, 41, 1);
          }
        }
        p{
            font-size: 24px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 5px;
            @media screen and (max-width: 992px){
                font-size: 18px;
                line-height: 28px;
            }
        }
      }
      .mySwipper{
          .swiper-wrapper{
              margin-top: 150px;
              margin-bottom: 100px;
              @media screen and (max-width: 992px){
                  margin-top: 100px;
                  margin-bottom: 100px;
                
              }
            .swiper-slide{
                height: 100%;
                .ecommerceFeatures__card{
                    background: #0B0B0B;
                    border-radius: 30px;
                    padding: 30px;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                    position: relative;
                    img{
                        width: 140px;
                        height: 140px;
                        margin-top: -100px;
                        margin-bottom: 20px;
                    }
                    h3{
                        font-size: 24px;
                        font-weight: 700;
                        color: #fff;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 18px;
                        color: rgba(255, 255, 255, 0.7);
                        margin-bottom: 0;
                    }
                }
            }
        }
        .swiper-button-next,.swiper-button-prev {
            position: absolute;
            right: 0;
            left: 50%;
            top: 100%;
            transform: translateY(-35px);
            width: 47px;
            font-weight: bold;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #DDA72A;
            background-color: #DDA72A;
            border-radius: 10px
        }
        
        .swiper-button-next:after,.swiper-button-prev:after {
            font-size: 20px;
            color: #222222
        }
        
        .swiper-button-prev {
            transform: translate(-65px, -35px);
        }
      }
}