.hostingMind {
  background-color: #DCA629;
  padding: 150px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: 992px) {
    padding: 60px 0;
  }
  .title{
    text-align: left;
    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 20px;
    }
    h2{
      font-size: 60px;
      font-weight: 700;
      color:#fff;
      margin-bottom: 10px;
      @media (max-width: 1600px) {
        font-size: 44px;
      }
      span{
        color: #521212;
      }
    }
    p{
      font-size: 24px;
      color: #0B0B0B;
      margin: 20px 0;
      @media screen and (max-width: 600px){
        font-size: 18px;
        
      }
    }
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        font-size: 24px;
        color: rgba(255, 255, 255, 0.7);
        margin: 15px 0;
        display: flex;
        @media screen and (max-width: 600px){
          font-size: 18px;
          margin: 10px 0;
        }
        img{
          width: 30px;
          height: 30px;
          margin-top: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  .block{
    img{
      width: 100%;
      height: 100%;
      @media screen and (max-width: 992px){
       margin-bottom: 30px;
        
      }
    }
  }
}