.brandingWorks{
    padding: 150px 0;
    background: #DCA629;
    position: relative;
    overflow: hidden;
    z-index: 0;
    &::after{
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2168.94px;
        height: 100%;
        background: url(../../../../../public/images/services/ecommerceFeatures.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
    }
    @media screen and (max-width: 992px){
        padding: 100px 0;
    }
    @media screen and (max-width: 767px){
        padding: 80px 0;
    }
    .title {
        text-align: center;
    
        h2{
          font-size: 60px;
          font-weight: 700;
          color:#fff;
          margin-bottom: 50px;
          text-transform: capitalize;
          @media (max-width: 992px) {
            font-size: 44px;
          }
          span{
            color: #521212;
          }
        }
        p{
            font-size: 24px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 5px;
            @media screen and (max-width: 992px){
                font-size: 18px;
                line-height: 28px;
            }
        }
      }
      .mySwipper{
        .swiper-wrapper{
            padding-bottom: 100px;
            padding-top: 40px;
          .swiper-slide{
              height: 100%;
              &.swiper-slide-active{
                .workCard{
                    border-right: none !important;
                }
              }
                &.swiper-slide-prev{
                    .workCard{
                        border-right: none !important;
                    }
                }
                &.swiper-slide-next{
                    .workCard{
                        border-right: none !important;
                    }
                }

              .workCard{
                  background: transparent;
                  border-radius: 0;
                  padding: 0 30px;
                  border-left: 1px solid rgba(255, 255, 255, 0.7);
                  border-right: 1px solid rgba(255, 255, 255, 0.7);
                  width: 100%;
                  height: 100%;
                  min-height: 400px;
                  position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h2{
                    font-size: 100px;
                    color: rgba(11, 11, 11, 1);
                    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                    -webkit-text-stroke: 2px rgba(11, 11, 11, 1);
                    font-family: 'Sf Pro Display Bold';
                    line-height: 80px;
                }
                  h3{
                      font-size: 24px;
                      font-weight: 700;
                      color: #fff;
                      margin-bottom: 10px;
                  }
                  p{
                      font-size: 18px;
                      color: rgb(255, 255, 255,1);
                      margin-bottom: 0;
                  }
              }
          }
        }
        .swiper-pagination{
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
            .swiper-pagination-bullet{
            color: #fff;
            width: 40px;
            height: 40px;
            margin: 0 5px;
            border-radius: 50%;
            opacity: 0.4;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: 2px solid white;
            &.swiper-pagination-bullet-active{
                opacity: 1;
            }
            }
        }
    }
}