.makeDifferent {
    padding: 100px 0;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding: 70px 0;
    }

    .title {
        text-align: center;
        margin-bottom: 70px;

        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }

        h2 {
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;

            @media screen and (max-width: 992px) {
                font-size: 44px;
            }

            span {
                color: #DCA629;
            }
        }
    }

    .col-lg-3 {
        &:nth-child(2) {
            .block {
                margin-top: 100px;
                padding-bottom: 60px;

                @media screen and (max-width: 1000px) {
                    padding-bottom: 44px;
                }

                @media screen and (max-width: 992px) {
                    margin-top: 0px;
                    padding-bottom: 20px;
                }
            }
        }

        &:nth-child(4) {
            .block {
                margin-top: 100px;

                @media screen and (max-width: 992px) {
                    margin-top: 0px;
                }
            }
        }
    }

    .block {
        background: #DCA629;
        padding: 30px 30px 35px;
        border-radius: 25px;
        transition: all 0.4s ease;

        @media screen and (max-width: 1200px) {
            padding: 20px;
        }

        @media screen and (max-width: 992px) {
            margin-top: 25px !important;
        }

        h3 {
            font-size: 30px;
            font-weight: 600;
            color: #fff;
            margin: 20px 0;

            @media screen and (max-width: 1200px) {
                font-size: 24px;
            }
        }

        p {
            font-size: 18px;
            color: #0B0B0B;
            margin: 0;

            @media screen and (max-width: 1200px) {
                font-size: 16px;
            }
        }
    }

    &.BussinessExpansion {
        padding: 100px 0;
        overflow: hidden;
        background-color: #DCA629;

        @media screen and (max-width: 768px) {
            padding: 70px 0;
        }

        .title {
            text-align: center;
            margin-bottom: 70px;

            @media screen and (max-width: 768px) {
                margin-bottom: 40px;
            }

            h2 {
                font-size: 60px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 10px;

                @media screen and (max-width: 992px) {
                    font-size: 44px;
                }

                span {
                    color: #521212;
                }
            }
        }

        .col-lg-3 {
            &:nth-child(2) {
                .block {
                    margin-top: 100px;
                    padding-bottom: 60px;

                    @media screen and (max-width: 1000px) {
                        padding-bottom: 44px;
                    }

                    @media screen and (max-width: 992px) {
                        margin-top: 0px;
                        padding-bottom: 20px;
                    }
                }
            }

            &:nth-child(4) {
                .block {
                    margin-top: 100px;

                    @media screen and (max-width: 992px) {
                        margin-top: 0px;
                    }
                }
            }
        }

        .block {
            background: #1d1d1d;
            padding: 30px 30px 35px;
            border-radius: 25px;
            transition: all 0.4s ease;

            @media screen and (max-width: 1200px) {
                padding: 20px;
            }

            @media screen and (max-width: 992px) {
                margin-top: 25px !important;
            }

            h3 {
                font-size: 26px;
                font-weight: 600;
                color: #fff;
                margin: 20px 0;

                @media screen and (max-width: 1200px) {
                    font-size: 24px;
                }
            }

            p {
                font-size: 18px;
                color: #ffffffa1;
                margin: 0;

                @media screen and (max-width: 1200px) {
                    font-size: 16px;
                }
            }
        }
    }

}