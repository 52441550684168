.AiToolsTech{
    padding: 80px 0;
    overflow: hidden;
    position: relative;

    &::before{
        content: '';
        display: none;
        background-image: url(../../../../../../public/images/services/section-bg-before.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 0;
        transform: translateY(30px);
        
        @media only screen and (max-width:1600px){
            transform: translateY(200px);
        }
        @media only screen and (max-width:993px){
            transform: translateY(170px);
        }
        @media only screen and (max-width:768px){
            transform: translateY(150px);
        }
        @media only screen and (max-width:468px){
            transform: translateY(175px);
        }
    }

   
    @media screen and (max-width: 768px){
      padding: 50px 0;
    }
    .title{
        text-align: center;
        margin:0 auto 70px;
        max-width: 1000px;
        @media screen and (max-width: 992px){
            margin-bottom: 50px;
        }
        h2{
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: #DCA629;
            }
        }
    }
    p{
        font-size: 24px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 5px;
        @media screen and (max-width: 992px){
            font-size: 18px;
            line-height: 28px;
        }
    }
    .stack{
        display: flex;
        margin-top: 25px;
        align-items: center;
        border-bottom: 2px solid #ffcf5e8e;
        padding-bottom: 20px;
        @media only screen and (max-width:994px){
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 10px;
        }
        p{
            padding-left: 3px;
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 550;
            min-width: 320px;
            @media only screen and (max-width:994px){
                min-width: 100%;
            }
        }
        .d-flex{
            flex-wrap: wrap;
        }
    }
    .btn-center{
        text-align: center;
        margin-top: 120px;
        @media screen and (max-width: 768px){
            margin-top: 60px;
        }
    }
}