.SaasDevProcess{
    padding: 120px 0;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 768px){
      padding: 50px 0;
    }
    .title{
        text-align: center;
        margin:0 auto 70px;
        max-width: 1000px;
        @media screen and (max-width: 992px){
            margin-bottom: 50px;
        }
        h2{
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: #DCA629;
            }
        }
        p{
            font-size: 24px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 5px;
            @media screen and (max-width: 992px){
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .stack{
        display: flex;
        margin-top: 25px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.377);
        padding-bottom: 30px;
        @media only screen and (max-width:994px){
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 10px;
        }
        p{
            color: #ffffff9a;
        }
        .btn-process{
            background-color: transparent;
            border: 1px solid #ffffff9a;
            outline: none;
            padding: 8px 26px;
            border-radius: 20px;
            color: #ffffff9a;
        }
        .p-title{
            display: flex;
            color: #fff;
            align-items: center;
            grid-gap: 50px;
            span{
                font-size: 22px;
                font-weight: bold;
            }
        p{
            padding-left: 3px;
            margin-bottom: 0;
            color: #fff;
            font-size: 22px;
            font-weight: 550;
            min-width: 320px;
            @media only screen and (max-width:994px){
                min-width: 100%;
            }
        }
    }
        .d-flex{
            flex-wrap: wrap;
            grid-gap: 12px;
        }
    }
    .btn-center{
        text-align: center;
        margin-top: 120px;
        @media screen and (max-width: 768px){
            margin-top: 60px;
        }
    }
}