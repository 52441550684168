.WebRecentWork{
    padding: 100px 0 200px;
    overflow: hidden;
    @media screen and (max-width: 768px){
        padding: 40px 0 90px;
    }
    .title{
        margin-bottom: -60px;
        h2{
            font-size: 60px;
            font-weight: 700;
            color:#fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: #dca629;
                @media only screen and (max-width:600px){
                    display: block;
                }
            }
        }
    }
    .swiper_container {
        height: auto !important;
        padding: 120px 0 70px;
        overflow: hidden;
        .swiper-slide {
            height: 100% !important;
            border-radius: 17px;
            z-index: 10;
            position: relative;
            transition: .3s ease-in;
            
            &.swiper-slide-active{
                transition: .3s ease-in;
                .image-wrapper{
                    transition: .3s ease-in;
                    &::after{
                        backdrop-filter: none !important;
                    }
                }
            }
            &:hover .slide-title{
                display: block;
            }
            .slide-title {
            transition: .3s ease-in;
              display: none;
              color: white;
              text-align: left;
              padding: 30px;
              position: absolute;
              bottom: 25px;
              border-radius: 25px;
              left: 10%;
              z-index: 2;
              background-color: #DDA72A;
              width: 80%;

              h2{
                font-weight: 600;
                font-size: 28px;
              }
              p{
                color: #ffffffb0;
                max-width: 88%;
              }
              a{
                color: #fff;
                text-transform: uppercase;
                text-decoration: underline !important;
              }

              @media only screen and (max-width:600px){
                overflow-y: hidden;
                padding: 20px;
                bottom: 10px;
                background-color: #222222;
                h2{
                    font-size: 22px;
                }
                p{
                    max-width: 100%;
                    font-size: 14px;
                }
              }
            }
            .image-wrapper {
             position: relative;
             
              img {
                width: 100% !important;
                height: 578px !important;
                object-fit: cover;
                border-radius: 17px;
                @media screen and (max-width: 992px){
                    height: 378px !important;
                }
                @media screen and (max-width: 565px){
                    height: 100% !important;
                    width: 95% !important;
                    margin: 0 10px;
                }
              }
            }
          }
          .swiper-pagination{
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            z-index: 2;
            .swiper-pagination-bullet{
                background: #dda72a49;
                
                width: 35px;
                height: 5px;
                display: inline-block;
                border-radius: 0%;
                margin: 0 5px;
                opacity: 1;
                @media screen and (max-width: 768px){
                    width: 13px;
                    height: 13px;
                    
                }
            }
            .swiper-pagination-bullet-active{
                background: #DDA72A;
            }
          }
          .swiper-button-next,.swiper-button-prev {
            position: absolute;
            right: 10%;
            top: 0;
            left: unset;
            top: 25px;
            width: 47px;
            font-weight: bold;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #DDA72A;
            background-color: #DDA72A;
            color: #0b0b0b !important;
            border-radius: 10px
        }
        .swiper-button-next:after,.swiper-button-prev:after {
            font-size: 20px;
            color: #222222
        }
        
        .swiper-button-prev {
            transform: translateX(-65px);
        }
      }
      .btn-center{
        text-align: center;
        margin-top: 80px;
        @media screen and (max-width: 768px){
            margin-top: 50px;
        }
    }
}