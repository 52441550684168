.hostingWorks{
      padding: 150px 0;
      background: #0B0B0B;
      overflow: hidden;
      position: relative;
      margin: 100px 0;
      @media screen and (max-width: 992px){
         padding: 50px 0;
         margin: 30px 0;
        }
        .title{
            text-align: center;
            margin-bottom:80px;
            @media screen and (max-width: 992px){
                margin-bottom: 55px;
            }
            h2{
                font-size: 60px;
                font-weight: 700;
                color:#fff;
                margin-bottom: 10px;
                @media screen and (max-width: 992px){
                    font-size: 44px;
                }
                span{
                    color: #DCA629;
                }
            }
            p{
                font-size: 24px;
                color: rgba(255, 255, 255, 0.7);
                @media screen and (max-width: 600px){
                    font-size: 18px;
                }
            }
        }
        .block{
            background: #222222;
            backdrop-filter: blur(30px);
            padding: 20px;
            border-radius: 30px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            gap: 15px;
            height: 100%;
            transition: all 0.3s ease;
           @media screen and (max-width: 992px){
               height: auto;         
           }
            &:hover{
                transform: translateY(-5px);
            }
            img{
                width: 65px;
                height: 65px;
            }
            h3{
                font-size: 24px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 0;
                font-family: "Sf Pro Display Bold";
                @media screen and (max-width: 992px){
                    font-size: 18px;
                }
            }
            p{
                font-size: 18px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 0;
                @media screen and (max-width: 992px){
                    font-size: 14px;
                }
            }
        }
}