.retailReach{
    padding: 70px 0 150px;
    overflow: hidden;
    @media screen and (max-width: 992px){
        padding: 60px 0 100px;
    }
    .banner{
        width: 100%;
        height: auto;
    }
    .content{
        padding-top: 60px;
        h2{
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 40px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: rgba(220, 166, 41, 1);
            }
        }
        p{
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 0;
            margin-top: 30px;
            color: rgba(255, 255, 255, 0.7);
            @media screen and (max-width: 992px){
                font-size: 18px;
                line-height: 28px;
            }
        }
        ul{
            margin-top: 30px;
            margin-left: 0;
            padding: 0;
            list-style: none;
            li{
                font-size: 20px;
                color: #fff;
                margin-top: 20px;
                display: flex;
                @media screen and (max-width: 992px){
                    font-size: 18px;
                    line-height: 28px;
                }
                img{
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                    margin-top: 5px;
                }
            }
        }
    }
}