.brandingStaff{
padding: 150px 0;
background: #0B0B0B;
position: relative;
overflow: hidden;
@media screen and (max-width: 992px){
    padding: 100px 0;
}
@media screen and (max-width: 767px){
    padding: 80px 0;
}
.team{
  p{
    color: #fff;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 50px;
    border-bottom: 1px solid #7B7C88;
    border-right: 1px solid #7B7C88;
  }
}
.title {
   display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    gap: 20px;
    @media screen and (max-width: 767px){
        flex-direction: column;
        align-items: flex-start;
    }

    h2{
      font-size: 60px;
      font-weight: 700;
      color:#fff;
      margin-bottom: 0px;
      @media (max-width: 992px) {
        font-size: 44px;
      }
      span{
        color: rgba(220, 166, 41, 1);
        display: block;
      }
    }
    img{
      width: 275px;
      height: auto;
    }
  }
  .col-md-3{
    &:nth-child(even){
      .blockCards{
        margin-top: 37px;
        @media screen and (max-width: 767px) {
          margin-top: 0;
        }
      }
    }
  }

  .block {
    border-radius: 40px;
    height: 100%;
    position: relative;
    .imgWrapper{
      position: relative;
      overflow: hidden;
      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: url(../../../../../public/images/services/gradient.svg);
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0px;
      }
    }

    .heading {
      line-height: 36px;
      color: rgb(255, 255, 255);
      font-size: 24px;
      font-weight: 600;
      @media screen and (max-width: 992px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .tag{
      margin: 15px 0 10px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0px;
      position: relative;
      @media screen and (max-width: 992px) {
        font-size: 16px;
      }
    }

  }
}