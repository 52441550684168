.ourServices{
      padding: 150px 0;
      background: rgba(220, 166, 41, 1);
      overflow: hidden;
      position: relative;
      margin: 100px 0;
      &::before{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../../../public/images/aboutUs/serviceBack.svg);
            background-size: cover;
            background-position: center;
        top: 0;
          z-index: 0;
      }
      @media screen and (max-width: 992px){
         padding: 50px 0;
         margin: 30px 0;
        }
        .title{
            text-align: center;
            margin-bottom:80px;
            @media screen and (max-width: 992px){
                margin-bottom: 55px;
            }
            h2{
                font-size: 60px;
                font-weight: 700;
                color:rgba(82, 18, 18, 1);
                margin-bottom: 10px;
                @media screen and (max-width: 992px){
                    font-size: 44px;
                }
            }
        }
        .block{
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(30px);
            padding: 15px;
            border-radius: 15px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            gap: 15px;
            transition: all 0.3s ease;
           
            &:hover{
                transform: translateY(-5px);
            }
            img{
                width: 65px;
                height: 65px;
            }
            h3{
                font-size: 20px;
                font-weight: 700;
                color: rgba(18, 18, 18, 1);
                margin-bottom: 0;
                font-family: "Sf Pro Display Bold";
                @media screen and (max-width: 992px){
                    font-size: 18px;
                }
            }
        }
        .btn-center{
            text-align: center;
            margin-top: 60px;
            @media screen and (max-width: 768px){
                margin-top: 40px;
            }
        }
}