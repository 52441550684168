.footer {
    padding: 90px 0 30px;
    overflow: hidden;
    background-color: #222222;

    @media screen and (max-width:992px) {
        padding: 70px 0 30px;
    }

    .logo {
        margin-bottom: 20px;
        border-bottom: 1.5px solid #DCA629;
        padding-bottom: 20px;
        margin-top: -28px;

        img {
            width: 91px;
        }
    }

    p {
        font-size: 16px;
        color: #FFFFFFB2;
        margin-bottom: 30px;
        max-width: 300px;

        span {
            color: #DCA629;
        }
    }

    .social {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 30px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    .links {
        h4 {
            font-size: 24px;
            color: #fff;
            margin-bottom: 20px;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            border-bottom: 1.5px solid #DCA629;
            padding-bottom: 20px;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            @media screen and (max-width: 992px) {
                margin-bottom: 30px;
            }

            li {
                margin-bottom: 15px;

                button,
                a {
                    color: #FFFFFFB2;
                    font-size: 18px;
                    text-transform: capitalize;
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    padding: 0;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

        .formGroup {
            position: relative;
            width: 100%;
            margin-bottom: 20px;

            input {
                width: 100%;
                padding: 10px 15px;
                border: 1px solid #fff;
                border-radius: 100px;
                background-color: transparent;
                color: #fff;
                font-size: 16px;
                outline: 0;

                &::placeholder {
                    color: #BDBFD4;
                }
            }

            button {
                background: #fff;
                color: #02021A;
                border: none;
                padding: 6px 12px;
                border-radius: 100px;
                font-size: 16px;
                cursor: pointer;
                position: absolute;
                right: 4px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        p {
            font-size: 16px;
            color: #FFFFFFB2;
            margin-bottom: 30px;
            max-width: 400px;
        }
    }

    .copRight {
        text-align: center;
        margin-top: 40px;
        padding-top: 30px;
        border-top: 1px solid #3A3A3A;

        @media screen and (max-width: 992px) {
            margin-top: 30px;
        }

        p {
            font-size: 16px;
            color: #FFFFFFB2;
            margin: 0 auto;

            span {
                color: #DCA629;
            }
        }
    }
}



// new footer css
.newfooter {
    font-family: "Onest", serif;
    overflow-x: hidden;
    

    .footertitle {
        color: #DCA629;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.44px;
        text-transform: uppercase;
    }

    .footerbox ul li a {
        color: #FFF;
        font-family: Onest;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px;
        text-transform: uppercase;
    }

    .Newsletter {
        p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }

        form {
            input {
                border-radius: 100px !important;
                border: 1px solid rgba(255, 255, 255, 0.24) !important;
                background: rgba(255, 255, 255, 0.04) !important;
                backdrop-filter: blur(30px) !important;
                color: #FFF !important;
                padding: 24px !important;
                margin-bottom: 24px;
            }

            ::placeholder {
                color: #FFF !important;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.72px;
                text-transform: uppercase;
                opacity: 0.6;
            }

            button {
                border-radius: 32px;
                background: #DCA629;
                border: 0;
                padding: 22px 42px;
            }

            .footerbottom {}
        }
    }

    .footerbottom {

        .container::after {
            content: none;
        }

        .sociallink {
            color: #DCA629;
            font-size: 28px;
        }
    }
}

.newfooter::after {
    content: '';
    width: 234px;
    height: 206.438px;
    border-radius: 142px;
    background:  #DCA629;
    filter: blur(250px);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;

}