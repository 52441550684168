/* RequestQuote.scss */

.requestQuote {
    padding: 100px 0;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }

    .title {
        text-align: center;
        margin-bottom: 70px;

        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }

        h2 {
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;

            @media screen and (max-width: 992px) {
                font-size: 44px;
            }

            span {
                color: #DCA629;
            }
        }
    }
}

.innerBlock {
    background-color: #DCA629;
    /* Matches the yellow background */
    padding: 2rem;
    border-radius: 30px;

    @media screen and (max-width: 992px) {
        padding: 2rem 1rem;
        border-radius: 20px;
    }
    h5{
        font-family: "Roboto", sans-serif;
    }
}

h5 {
    font-weight: bold;
    margin-bottom: 30px !important;
}

.selectService {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @media screen and (max-width: 600px) {
       justify-content: center;
     }

    .checkBoxes {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 15px;
        padding: 15px;
        width: 31.33%;
        @media screen and (max-width: 600px) {
           width: 45%;
        }
        label {
            text-align: center;
            font-size: 16px;
            color: #121212;
            font-weight: 600;
            line-height: normal;
        }
    }
}

.form-check .form-check-input {
    margin-left: 0 !important;
}

.or {
    text-align: center;
    margin: 20px 0;
    font-size: 60px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: #0B0B0B;
}

.schedule {
    @media screen and (max-width: 992px) {
        margin-bottom: 50px;
    }

    text-align: center;

    a {
        text-decoration: underline !important;
        color: #0B0B0B;
        font-size: 18px;
        font-weight: 500;
    }
}

.borderLeft {
    border-left: 1px solid #FFD168;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
        border-left: none;
        padding-left: 20px;
    }
}

form {

    input,
    textarea {
        background-color: rgba(255, 255, 255, 0.4) !important;
        /* Light beige-yellow background to match design */
        border: 1px solid rgba(255, 255, 255, 0.4) !important;
        color: #000 !important;
        padding: 10px !important;
        border-radius: 10px !important;
        font-size: 14px !important;
        box-shadow: none !important;

        &::placeholder {
            color: #121212 !important;
        }
    }

    label {
        font-size: 18px;
        color: #121212;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 12px;
    }

    button {
        background-color: #e08b00;
        /* Custom submit button color */
        color: #fff;
        font-weight: bold;
        padding: 0.8rem;
        border-radius: 30px;
        border: none;
        width: 100%;
        cursor: pointer;
    }

    .service-option {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        margin-bottom: 2rem;
    }

    .service-option .form-check {
        width: 45%;
        display: flex;
        justify-content: center;
        border: 2px solid #000;
        padding: 10px;
        background-color: #f4d580;
        border-radius: 10px;
    }

    .budget-slider {
        position: relative;

        .budget-amount {
            position: absolute;
            top: -30px;
            right: 0;
            font-size: 18px;
            font-weight: 600;
            font-family: "Roboto", sans-serif;
        }

        input[type="range"] {
            appearance: none;
            background-color: rgba(255, 255, 255, 0.4) !important;
            height: 41px;
            width: 100%;
            border-radius: 10px !important;
            border: 1px solid #e9c97e !important;

            &::-webkit-slider-runnable-track {
                height: 40px;
                background: linear-gradient(to right, #000 0%, #000 var(--value, 50%), #dca629 var(--value, 50%), #dca629 100%);
                border-radius: 2px;
            }

            &::-webkit-slider-thumb {
                appearance: none;
                width: 20px;
                height: 40px;
                border-radius: 4px;
                background: #2A2A2A;
                cursor: pointer;
                border: 1px solid #DCA629;
                position: relative;
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.4);
            }

            &::-moz-range-thumb {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #000;
                cursor: pointer;
                border: 2px solid #fff;
            }

        }

    }

    .preferred-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 10px;

        input[type="time"] {
            background-color: transparent !important;
            border: none !important;
            padding: 10px;
            font-size: 18px;
            border-radius: 10px;
            width: 60%;
        }

        .am-pm-buttons {
            display: flex;
            margin-left: 10px;
            margin-right: 10px;

            button {
                width: 30px;
                height: 30px;
                display: flex;
                font-size: 12px;
                justify-content: center;
                align-items: center;
                color: rgba(18, 18, 18, 0.7);
                background-color: transparent;
                border: none;
                border-radius: 5px;
                font-weight: bold;
                cursor: pointer;
                margin-left: 5px;
                outline: none;
            }

            .active {
                background-color: #DCA629;
                color: #0B0B0B;
            }
        }
    }
}
.alert{
    border-radius: 10px !important;
    background: #eaca7f !important;
    font-size: 14px;
}
.error{
    color: #d00014;
    font-size: 14px;
    padding: 5px 12px 0;
}