.hostingDomain {
  background-color: #0B0B0B;
  padding: 150px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: 992px) {
    padding: 80px 0;
  }
  .title{
    text-align: left;
    max-width: 570px;
    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 20px;
    }
    h2{
      font-size: 60px;
      font-weight: 700;
      color:#fff;
      margin-bottom: 10px;
      @media (max-width: 1600px) {
        font-size: 44px;
      }
      span{
        color: #DCA629;
      }
    }
    p{
      font-size: 24px;
      color: rgba(255, 255, 255, 0.7);
      margin: 20px 0;
      @media screen and (max-width: 600px){
        font-size: 18px;
        
      }
    }
    .tagline{
      color: #DCA629;
      margin-bottom: 10px;
    }
    .btn-custom{
      margin-top: 20px;
      display: inline-block;
  
    }
  }
  .block{
        section {
          padding: 20px;
          text-align: center;
          background-color: #222222;
          border-radius: 15px;
          border: 1px solid #9E9AA7;
          @media (max-width: 1000px) {
            padding: 20px;
          }
          img{
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
          }
          h4 {
            margin: 0;
            font-size: 30px;
            color: #fff;
            font-weight: 600;
            white-space: pre;
            font-family: 'Sf Pro Display';
            @media screen and (max-width: 992px){
              font-size: 24px;
            }
            @media screen and (max-width: 600px){
              font-size: 18px;              
            }
          }
        }
  }
}