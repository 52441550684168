ul li {
  &.menu_active {
    .mini_dropdown {
      display: block;
    }
    .mega_menu_wrapper {
      opacity: 1;
      visibility: visible;
    }
    & > a,
    .menuwrap {
      color: #fff !important;
      &::after {
        left: 0 !important;
        width: 100% !important;
      }
      & > i {
        transform: scale(-1);
      }
    }
  }
}
.mega_menu_wrapper {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0;
  z-index: 9;
  background: #0B0B0B;
  border: 4px solid #303030;
  border-radius: 20px;
  width: 100%;
  height: 500px;
  overflow: auto;
  padding-top: 27px !important;
  .heading{
    color: #FBFCFC;
    font-size: 16px;
    line-height: 31px;
    margin-left: 20px;
    margin-bottom: 15px;
    display: block;
    text-transform: uppercase;
  }
  .containerDiv{
    & > li {
      width: 100%;
      & > a {
        width: 28%;
        font-size: 16px;
        position: relative;
        line-height: 34px;
        color: #FFFFFF;
        display: flex;
        font-family: 'CloverDisplay-Bold',sans-serif;
        transition: all 0.4s ease-in-out;
        align-items: center;
        padding: 12px;
        border-radius: 10px;
        margin-left: 10px;
        @media (max-width: 1600px) {
          font-size: 16px;
        }
        &.active {
          background-color: #1D1D1D;
          color: #fff;
          span {
            background-color: #0B0B0B;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: 10px;
            img {
              transition: all 0.4s ease-in-out;
              object-fit: contain;
              width: 30px;
              height: 30px;
            }
          }
          & ~ .mega_sub_menu {
            display: flex;
          }
        }
        span{
          background-color: #1d1d1d;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 10px;
          img {
            transition: all 0.4s ease-in-out;
            object-fit: contain;
            width: 30px;
            height: 30px;
          }
        }
      }
      .mega_sub_menu {
        width: 65%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
        height: 460px;
        overflow: auto;
        display: none;
        section {
          width: 65%;
          padding: 0 40px;
          display: flex;
          flex-direction: column;
          padding-top: 27px !important;
          background-image: url(../../../../public/images/services/sectionBack.svg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          .nav_title {
            border-bottom: 2px solid transparent;
            color: #DCA629;
            transition: all 0.4s ease-in-out;
            font-family: 'CloverDisplay-Bold',sans-serif;
            text-transform: uppercase;
            display: flex;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 31px;
            justify-content: flex-start;
            margin-right: auto;
          }
          li {
            line-height: 1;
            margin-bottom: 16px;
            @media (max-width: 1600px) {
              margin-bottom: 10px;
            }
            a {
              color: #FBFCFCB2;
              font-size: 16px;
              line-height: 28px;
              padding-bottom: 13px;
              display: flex;
              transition: all 0.4s ease-in-out;
              gap: 10px;
              align-items: center;
              font-family: "CloverDisplay-Bold", sans-serif;
              img{
                width: 25px;
                height: 25px; 
              }
              @media (max-width: 1600px) {
                font-size: 16px;
                line-height: 20px;
              }
            }
            &:hover {
              a {
                color: #fffffff7;
              }
            }
          }
        }
        .img{
          width: 35%;
          display: flex;
          justify-content: center;
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
    }
  }
}