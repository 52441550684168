.schedule{
    padding: 100px 0;
    overflow: hidden;
    @media screen and (max-width: 768px){
      padding:  100px 0 0;
    }
    .block{
        background: #222222;
        border: 3px dashed #DDA72A;
        border-radius: 1000px;
        padding: 10px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px){
            flex-direction: column;
            border-radius: 20px;
            padding: 20px;
          }
        img{
            width: 300px;
            height: 300px;
            @media screen and (max-width: 600px){
                width: 80px;
                height: 80px;
            }
        }
        .content{
            width: 100%;
            text-align: center;
            max-width: 658px;
            margin: 0 auto;
            h3{
                font-size: 45px;
                font-weight: 700;
                color: #fff;
                line-height: normal;
                margin-bottom: 0px;
                @media screen and (max-width: 992px){
                    font-size: 40px;
                }
                @media screen and (max-width: 600px){
                    font-size: 22px; 
                    margin-bottom: 0;
                }
                span{
                    color: #dca629;
                }
            }
            .heading{
                font-size: 18px;
                color: rgba(255, 255, 255, 0.7);
                margin: 20px 0;
                line-height: normal;
                @media screen and (max-width: 600px){
                    font-size: 16px; 
                }
            }
            .subHeading{
                font-size: 24px;
                color: #fff;
                margin-bottom: 0;
                line-height: normal;
                @media screen and (max-width: 992px){
                    font-size: 18px;
                }
            }
        }
    }
}