.needMaintenance{
    padding: 200px 0;
    position: relative;
    @media screen and (max-width: 1200px) {
        padding: 100px 0;
    }
    @media screen and (max-width: 992px) {
        padding: 50px 0;
    }
    .title{
        background-color: #222222;
        padding: 40px;
        border-radius: 30px;
        position: relative;
        z-index: 99;
        @media screen and (max-width: 992px) {
            padding: 20px;
        }
        h2{
            font-size: 53px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 20px;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 44px;
            }
            span{
                color: rgba(220, 166, 41, 1);
            }
        }
        .line{
            width: 60px;
            height: 3px;
            background-color: rgba(220, 166, 41, 1);
            margin-bottom: 20px;
            border-radius: 10px;
        }
        p{
            color:rgba(255, 255, 255, 0.7);
            font-size: 20px;
            @media screen and (max-width: 992px){
                font-size: 16px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .imgBlock{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 1200px) {
            position: relative;
            top: unset;
            transform: unset;
        }
        @media screen and (max-width: 992px) {
            display: none;
        }
        img{
            transform: scale(1.3);
            height: 452px;
            width: 100%;
            @media screen and (max-width: 1600px) {
                height: 380px;
            }
            @media screen and (max-width: 1200px) {
                transform: scale(1);
                height: 100%;
                
            }
        }
    }
}