.about{
    background: url(../../../../public/images/aboutUs/about.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0 0;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 992px){
        padding: 85px 0 0;       
    }
    .title{
        max-width: 645px;
        margin: 0 auto;
        h2{
            font-size: 60px;
            font-weight: 700;
            color:#fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: rgba(220, 166, 41, 1);
        
            }
        }
        p{
            color:rgba(255, 255, 255, 0.7);
            font-size: 20px;
            @media screen and (max-width: 992px){
                font-size: 16px;
                
            }
        }
    }
}
    .aboutBanner{
        padding: 100px 0;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        @media screen and (max-width:992px) {
            padding: 50px 0;
        }
        .bannerContent{
            text-align: left;
        height: 100%;

            .title{
                h2{
                    font-size: 60px;
                    font-weight: 700;
                    color:#fff;
                    margin-bottom: 10px;
                    @media screen and (max-width: 992px){
                        font-size: 44px;
                    }
                    span{
                        color: rgba(220, 166, 41, 1);
            
                    }
                }
            }
            p{
                color:rgba(255, 255, 255, 0.7);
                font-size: 20px;
                @media screen and (max-width: 992px){
                    font-size: 16px;
                    
                }
            }
        }
        .aboutImg{
            img{
                width: 100%;
                height: 100%;   
                border-radius: 20px;
                @media screen and (max-width:600px) {
                    margin-top: 20px;
                }
            }
        }
    }
