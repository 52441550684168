@import "../../assets/css/variables.scss";
.main_header {
  margin-bottom: -84px;
  z-index: 999;
  position: absolute;
  width: 100%;
  background-color: #1d1d1d;
  @media (max-width: $breakpoint_laptop) {
    margin-bottom: -64px;
  }
}
.d-xl-none{
  display: none;
  @media screen and (max-width: $breakpoint_tablet) {
    display: block;
  }
}
.mobileHeader {
  background-color: #1D1D1D;
  padding: 10px 20px;
  position: relative;
  z-index: 1000;
  display: none;
  @media screen and (max-width: $breakpoint_tablet) {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 3;
  }

  .mobileHeaderPadding {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }

  .mobileLogo {
    width: auto;
    height: 45px;
    cursor: pointer;
  }

  .toggleImg {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
}

/* Header.module.scss */



.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 295px;
  height: 100%;
  background:#1D1D1D ;
  background-size: cover;
  background-position: center;
  transition: right 0.3s ease;
  color: #fff;
  z-index: 1000;
  overflow-y: auto;
  padding: 30px;
  display: none;
  @media screen and (max-width: $breakpoint_tablet) {
    display: block;
  }

  &.active {
    right: 0;
  }

  .sidebarHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    a{
      img {
        cursor: pointer;
        width: auto;
        height: 40px;
      }
    }

    i {
      font-weight: 900;
      color: #fff;
      background: #DCA629;
      padding: 8px 11px;
      font-size: 13px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .menu {
    a {
      color: #fff;
      margin: 0 auto;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 17px 0;
      font-size: 14px;
      display: block;
      text-decoration: none;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .subMenu {
    span {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 0;
      font-size: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      &:hover {
        background-color: transparent;
      }

      .expandIcon {
        transform: rotate(-45deg);
        border-right: 2px solid currentcolor;
        border-bottom: 2px solid currentcolor;
        width: 5px;
        height: 5px;
        transition: transform 0.3s;

        &.open {
          transform: rotate(44deg);
        }
      }
    }

    .subMenuContent {
      a {
        color: #fff;
        padding: 17px 0;
        font-size: 14px;
        display: block;
        text-decoration: none;
      }
    }
  }

  .socialLinks {
    padding: 17px 0;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    a {
      text-align: center;
      filter: grayscale(1);

      i {
        font-size: 21px;
        color: #fff;
        cursor: pointer;
      }
    }
  }

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
  display: none;
  @media screen and (max-width: $breakpoint_tablet) {
    display: block;
  }
}


.header_widget {
  position: relative;
  padding: 10px 0;
  background: #DCA629;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  @media screen and (max-width: $breakpoint_laptop) {
   padding: 3px 0;
  }
  @media screen and (max-width: $breakpoint_tablet) {
    display: none;
  }
  .header_section {
    .contact_margin {
      margin-right: 18px;
      @media screen and (max-width: $breakpoint_mini_laptop) {
        margin-right: 14px;
      }
    }
    .contact_section {
      li {
        margin-right: 40px;
        @media (max-width: $breakpoint_laptop) {
          margin-right: 25px;
        }
        &:last-child { margin-right: 0;}
        img {
          margin-right: 20px;
          @media (max-width: $breakpoint_laptop) {
            width: 30px;
            margin-right: 10px;
          }
        }
        section {
          @media (max-width: $breakpoint_laptop) {
            line-height: 1.23;
          }
          a {
            color: #fff;
            font-size: 16px;
            transition: all 0.4s ease-in-out;
            font-family: 'Roboto',sans-serif;
            @media (max-width: $breakpoint_laptop) {
              font-size: 14px;
            }
          }
        }
        &:hover {
          section {
            a {
              color: #1D1D1D;
            }
          }
        }
      }
    }
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 180px;
        height: auto;
      }
    }
    .career_section {
      ul {
        li {
          margin-right: 50px;
          @media (max-width: $breakpoint_laptop) {
            margin-right: 30px;
          }
          &:last-child { margin-right: 36px;}
          .quick_link_content {
            text-align: center;
            img {
              margin-bottom: 8px;
              @media (max-width: $breakpoint_laptop) {
                margin-bottom: 2px;
                width: 24px;
              }
            }
            span {
              transition: all 0.4s ease-in-out;
              margin: 0;
              color: $dark_color;
              font-size: 16px;
              line-height: 20px;
              font-family: 'Roboto', sans-serif;
              @media (max-width: $breakpoint_laptop) {
                font-size: 15px;
              }
            }
            &:hover {
              span {
                color: $green_color;
              }
            }
          }
        }
      }
    }
  }
}
.navigation_wrapper {
  background-color: #1D1D1D;
  padding: 5px 0;
  @media screen and (max-width: $breakpoint_tablet) {
    display: none;
  }
  &.is_sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #1D1D1D !important;
    box-shadow: 0 2px 24px 0 rgba(0 0 0 / 15%);
    transition: all 0.4s ease-in-out;
    .navigation_content {
      & > a{
        display: block;
        img {
          display: block;
        }
      }
      .main_menu {
        & > li {
          & > a,.menuwrap {
            color: #fff9;
            padding: 13px 7px;
            font-size: 16px;
          }
        }
      }
    }
    .header_social_wrapper {
      span {
        color: $dark_color !important;
        a {
          color: $dark_color !important;
          &:hover { color: $green_color !important}
        }
      }
      .social_links {
        .social {
          background-color: $dark_color;
          border-color: $dark_color;
        }
      }
    }
  }
  .navigation_content {
    align-items: center;
    & > a{
      display: none;
      img {
        max-width: 150px;
        height: auto;
      }
    }
    .main_menu {
      align-items: center;
      .menu_active {
        .mini_dropdown {
          display: block;
        }
      }
      & > li {
        list-style: none;
        margin-right: 14px;
        @media screen and (max-width: $breakpoint_mini_laptop) {
          margin-right: 15px;
        }
        & > a,
        .menuwrap {
          background: none;
          border: none;
          line-height: 1;
          position: relative;
          color: #FFFFFF99;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          font-size: 16px;
          display: flex;
          text-transform: uppercase;
          padding: 10px 7px;
          transition: all 0.4s ease-in-out;
          @media screen and (max-width: $breakpoint_laptop) {
            padding: 10px 7px;
          }
          i {
            font-size: 15px;
            margin-left: 10px;
          }
          // &::after {
          //   content: "";
          //   position: absolute;
          //   width: 0;
          //   height: 4px;
          //   border-radius: 10px;
          //   background-color: $green_color;
          //   bottom: -2px;
          //   left: 50%;
          //   transition: all 0.4s ease-in-out;
          // }
          .logo{
            width: 85px;
            height: 55px;
          }
        }
        &:hover {
          & > a,
          .menuwrap {
            color: #fff;
            // &::after {
            //   left: 0;
            //   width: 100%;
            // }
          }
        }
      }
    }
    .header_social_wrapper {
      span {
        color: $white_color;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        margin-right: 34px;
        @media screen and (max-width: $breakpoint_laptop) {
          font-size: 16px;
          margin-right: 10px;
        }
        a {
          text-decoration: underline;
          font-family: 'Roboto', sans-serif;
          margin-left: 10px;
          color: $white_color;
          &:hover {
            color: $green_color;
          }
        }
      }

    }
  }
}
.mini_dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  width: 300px;
  background: #0B0B0B;
  border: 4px solid #303030;
  box-shadow: 0 11px 42px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 15px 25px;
  li {
    display: flex;
    flex-direction: column;
    &:last-child {
      a { border-bottom: none;}
    }
    a {
      width: 100%;
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      padding: 15px 0;
      border-bottom: 1px solid #D6D6D6;
      transition: all 0.4s ease-in-out;
    }
    &:hover,
    &.active{
      a {
        color: $green_color;
      }
    }
  }
}
.social_links {
  .social {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-left: 13px;
    transition: all 0.4s ease-in-out;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    &:hover {
      box-shadow: 0 5px 10px rgba(0,0,0,0.3);
    }
  }
}