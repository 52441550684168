.results_wrapper {
  background-color: #DCA629;
  padding: 150px 0;
  position: relative;
  overflow: hidden;
  &::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background: url(../../../../public/images/portfolio/chooseBack.svg);
    background-size: cover;
    background-position: center;
    z-index: 0;
    background-repeat: no-repeat;
  }
  @media (max-width: 992px) {
    padding: 80px 0;
  }
  .title{
    text-align: left;
    max-width: 420px;
    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 20px;
    }
    h2{
      font-size: 60px;
      font-weight: 700;
      color:#521212;
      margin-bottom: 10px;
      @media (max-width: 1600px) {
        font-size: 44px;
      }
      span{
        color: #DCA629;
      }
    }
    p{
      font-size: 24px;
      color: rgba(11, 11, 11, 0.6);
      @media screen and (max-width: 600px){
        font-size: 18px;
        
      }
    }
  }
  .col-md-4{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -100%);
      width: 60%;
      height: 1px;
      background-color: rgba(82, 18, 18, 0.2);
      @media screen and (max-width: 600px){
        display: none; 
      }
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6){
      &::after{
        display: none;
        @media screen and (max-width: 600px){
          display: none;
        }
      }
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5){
      &::before{
        content: "";
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translate(-100%, -50%);
        width: 1px;
        height: 60%;
        background-color: rgba(82, 18, 18, 0.2);
        @media screen and (max-width: 600px){
          display: none;
        }
      }
    }
  }
  .block{
        section {
          padding: 20px 10px;
          text-align: center;
          @media (max-width: 1000px) {
            padding: 20px;
          }
          img{
            width: 93px;
            height: 93px;
            margin-bottom: 20px;
          }
          h4 {
            margin: 0;
            font-size: 16px;
            color: #521212;
            font-weight: 600;
            white-space: pre;
            font-family: 'Sf Pro Display';
          }
        }
  }
}