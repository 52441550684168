.CaseStudies{
    padding: 100px 0 120px;
    overflow: hidden;
    background-color: #222222;
    @media screen and (max-width: 768px){
        padding: 40px 0 60px;
    }
    &.bg-dark{
        background-color: #c72b2b;
    }
    .title{
        margin-bottom: -60px;
        h2{
            font-size: 60px;
            font-weight: 700;
            color:#fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: #dca629;
                @media only screen and (max-width:999px){
                    display: block;
                }
            }
        }
    }
    .swiper_container {
        height: auto !important;
        padding: 120px 0 70px;
        overflow: hidden;
        .swiper-slide {
            height: 100% !important;
            border-radius: 17px;
            z-index: 10;
            position: relative;
            transition: .3s ease-in;
            
            &.swiper-slide-active{
                transition: .3s ease-in;
                .image-wrapper{
                    transition: .3s ease-in;
                    &::after{
                        backdrop-filter: none !important;
                    }
                }
            }
            .case-study-card{
                color: #fff;
                background-color: #0b0b0b;
                border-radius: 20px;             
                position: relative;
                    img{
                        border-radius: 20px 0 0 20px;
                        height: 100%;
                        overflow: hidden;
                        @media only screen and (max-width:993px){
                            display: none;
                        }
                    }
                .slider-content{
                    padding: 10px 60px 10px 30px;
                    @media only screen and (max-width:999px){
                        padding: 30px;
                    }
                    h4, h5{
                        font-weight: 550;
                    }
                    .tags{
                        display: flex;
                        flex-wrap: wrap;
                        grid-gap: 8px;
                        margin: 25px 0;
                        li{
                            background-color: #222222;
                            border-radius: 5px;
                            padding: 10px;
                            font-size: 14px;
                            display: block;
                            color: #ffffffd0;
                        }
                    }
                    h5{
                        max-width: 520px;
                    }
                    ul{
                        li{
                            list-style: none;
                            margin: 10px 0;
                            color: #ffffffa4;
                            font-weight: lighter;
                            position: relative;
                            &::after{
                                content: url(../../../../../../public/images/services/case-study-li.svg);
                                position: absolute;
                                left: -30px;
                                top: 4px;
                            }
                        }
                    }
                }
            }
        }
                                     
          .swiper-button-next,.swiper-button-prev {
            position: absolute;
            right: 10%;
            top: 0;
            left: unset;
            top: 25px;
            width: 47px;
            font-weight: bold;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #DDA72A;
            background-color: #DDA72A;
            color: #0b0b0b !important;
            border-radius: 10px;

            @media only screen and (max-width:2600px){
                right: 25%;
            }
            @media only screen and (max-width:2000px){
                right: 15%;
            }
            @media only screen and (max-width:1600px){
                right: 12%;
            }
            @media only screen and (max-width:1300px){
                right: 3%;
            }
        }
        .swiper-button-next:after,.swiper-button-prev:after {
            font-size: 20px;
            color: #222222
        }
        
        .swiper-button-prev {
            transform: translateX(-65px);
        }
      }
      .btn-center{
        text-align: center;
        margin-top: 80px;
        @media screen and (max-width: 768px){
            margin-top: 50px;
        }
    }

    .swiper-pagination{
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 40%);
        position: absolute;
        z-index: 992;
        padding: 5px 0;

        @media only screen and (max-width:768px){
            height: 50px;
            padding: 10px 0;
        }
        .swiper-pagination-bullet{
            background: #dda72a49;
            
            width: 35px;
            height: 5px;
            display: inline-block;
            border-radius: 0%;
            margin: 0 5px;
            opacity: 1;
            @media screen and (max-width: 768px){
                width: 13px;
                height: 13px;
                
            }
        }
        .swiper-pagination-bullet-active{
            background: #DDA72A;
        }
      }
}