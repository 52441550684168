.ecommerceWork {
    padding: 80px 0;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        background-image: url(../../../../../../public/images/services/work-before.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 0;
        transform: translateY(300px);

        @media only screen and (max-width:1600px) {
            transform: translateY(200px);
        }

        @media only screen and (max-width:993px) {
            transform: translateY(170px);
        }

        @media only screen and (max-width:768px) {
            transform: translateY(150px);
        }

        @media only screen and (max-width:468px) {
            transform: translateY(175px);
        }
    }

    @media screen and (max-width: 768px) {
        padding: 10px 0 50px;
    }

    .title {
        text-align: center;
        margin: 0 auto 70px;
        max-width: 1000px;

        @media screen and (max-width: 992px) {
            margin-bottom: 50px;
        }

        h2 {
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;

            @media screen and (max-width: 992px) {
                font-size: 44px;
            }

            span {
                color: #FFC700;
            }
        }

        p {
            font-size: 24px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 5px;

            @media screen and (max-width: 992px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .mySwipper {
        img {
            width: 100%;
            height: auto;
        }
    }

    .btn-center {
        text-align: center;
        margin-top: 80px;

        @media screen and (max-width: 768px) {
            margin-top: 60px;
        }
    }

    &.MvpRecentWork {
        &::before {
            display: none;
        }
    }

    &.seotWork {
        background-color: #DCA629;

        &::before {
            content: '';
            background-image: url(../../../../../../public/images/services/work-after.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0px;
            z-index: 0;
            transform: translateY(300px);
        }
    }
}