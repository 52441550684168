.maintenanceServices {
    padding: 100px 0 200px;
    overflow: hidden;
  
    @media screen and (max-width: 992px) {
      padding: 50px 0 40px;
    }
  
    .title {
      h2 {
        font-size: 55px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 10px;
        text-transform: capitalize;
        line-height: normal;
  
        @media screen and (max-width: 992px) {
          font-size: 44px;
        }
  
        span {
          color: rgba(220, 166, 41, 1);
        }
      }
  
      p {
        font-size: 22px;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 10px;
        line-height: 42px;
        @media screen and (max-width: 992px) {
          font-size: 18px;
          line-height: 32px;
        }
  
        &:nth-child(1) {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  
}
.videoWrapper {
  margin-top: 50px;
  background-color: #DCA629;
  padding: 0 0 80px;
  @media screen and (max-width: 992px) {
    padding: 50px 0;
    margin-top: 0;
  }
  
  .videoContainer {
      position: relative;
      video{
        margin-top: -200px;
        width: 100%;
        height: auto;
        border-radius: 40px;
        object-fit: cover;
        border: 4px solid rgba(255, 255, 255, 0.2);

        @media screen and (max-width: 992px) {
           margin-top: 0;
          }
    }

    .video.playing {
      display: inline-block; /* Show video once playing */
    }

    .custom-play-button {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 1;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 992px) {
        top: 50%;
       }

      .play-icon {
        width: 80px;
        height: 80px;
      }
    }

    /* Hide video until it's playing */
    .poster {
      width: 100%;
    }
  }
}
  