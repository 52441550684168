.ourTeam{
      padding: 50px 0 30px;
      overflow: hidden;
      @media screen and (max-width: 992px){
         padding:60px 0;
        }
        .title{
            text-align: center;
            margin-bottom:70px;
            @media screen and (max-width: 992px){
                margin-bottom: 50px;
            }
            h2{
                font-size: 60px;
                font-weight: 700;
                color:#fff;
                margin-bottom: 10px;
                @media screen and (max-width: 992px){
                    font-size: 44px;
                }
                span{
                    color: #FFC700;
                }
            }
        }
        .mainBlock{
            margin-bottom: 40px;
            transition: all 0.4s ease-in-out;
            &:hover{
                .wrapImg{
                    .description{
                        display: block;
                     }
                }
            }
            @media screen and (max-width: 992px){
                margin-bottom: 10px;
            }
            .wrapImg{
                position: relative;
                transition: all 0.4s ease-in-out;
                img{
                    width: 100%;
                    height: 100%;
                      object-fit: cover;
                      border-radius: 20px;
                      filter: grayscale(1);
                }
                .description{
                    // display: none;
                    background: rgba(29, 31, 32, 0.9);
                    padding: 25px;
                    border-radius: 25px;
                    width: 94%;
                    margin: 0 auto;
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    @media screen and (max-width: 992px){
                        padding: 20px;
                        width: 96%;
                        bottom: 10px;
                      }
                    h3{
                        font-size: 26px;
                        color: #fff;
                        margin-bottom: 10px;
                        font-weight: 600;
                        @media screen and (max-width: 992px){
                            font-size: 16x;
                          }
                          @media screen and (max-width: 600px){
                            font-size: 16px;
                          }
                    }
                    p{
                          font-size: 16px;
                          color: rgba(176, 176, 176, 1);
                          margin: 0;
                            @media screen and (max-width: 992px){
                                font-size: 12px;
                            }
                            @media screen and (max-width: 600px){
                                font-size: 12px;
                              }
                    }
                }
            }
            .wrap{
                display: flex;
                align-items: start;
                justify-content: space-between;
                padding: 25px 15px 0;
                @media screen and (max-width: 992px){
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;
                }
                .content{
                    h3{
                        font-size: 30px;
                        color: #fff;
                        margin-bottom: 10px;
                        font-weight: 600;
                        @media screen and (max-width: 992px){
                            font-size: 18px;
                            margin-bottom: 0;
                          }
                    }
                    span{
                          font-size: 18px;
                          color: rgba(176, 176, 176, 1);
                          margin: 0;
                            @media screen and (max-width: 992px){
                                font-size: 14px;
                            }
                    }
                }
                .social{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    a{
                        img{
                            width: 20px;
                            height: 20px;
                            border-radius: 0;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        .block{
            position: relative;
            margin-bottom: 40px;
            padding: 20px;
            border-radius: 40px;
            background: rgba(34, 34, 34, 1);
            @media screen and (max-width: 992px){
                margin-bottom: 10px;
                padding: 10px 10px 15px;
                border-radius: 20px;
            }
            img{
                width: 100%;
                height: 100%;
                  object-fit: cover;
                  border-radius: 20px;
                  filter: grayscale(1);
            }
            .wrap{
                display: flex;
                align-items: start;
                justify-content: space-between;
                padding: 25px 15px 0;
                @media screen and (max-width: 992px){
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;
                }
                .content{
                  h3{
                      font-size: 18px;
                      color: #fff;
                      margin-bottom: 5px;
                      font-weight: 600;
                        @media screen and (max-width: 992px){
                            margin-bottom: 0;
                            }
                  }
                  span{
                        font-size: 14px;
                        color: rgba(176, 176, 176, 1);
                        margin: 0;
                  }
              }
                .social{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    a{
                        img{
                            width: 20px;
                            height: 20px;
                            border-radius: 0;
                            object-fit: contain;
                            filter: none;
                        }
                    }
                }
            }
           
        }
}