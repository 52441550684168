.webDesignBanner{
    background: url(../../../../../../public/images/services/webDesignBanner.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 230px 0 0;
    overflow: hidden;
    @media screen and (max-width: 992px){
        padding: 115px 0 0;       
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}