.WebProcesses{
    padding: 150px 0 ;
    overflow: hidden;
    background-color: #222222;
    position: relative;
    @media screen and (max-width: 992px){
        padding: 100px 0 ;
    }
   &::before{
    content: url(../../../../../../public/images/services/yellowStar.svg);
    position: absolute;
    top: 100px;
    left: 50px;
    @media only screen and (max-width:800px){
      display: none;
    }
   }

    .title {
        max-width: 950px;
        text-align: center;
        margin: 0 auto;
    
        h2{
          font-size: 60px;
          font-weight: 700;
          color:#fff;
          margin-bottom: 10px;
          @media (max-width: 1600px) {
            font-size: 44px;
          }
          span{
            color: #DCA629;
          }
        }
      }
      .block{
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            li{
                margin: 100px 0 0;
                width: 150px;
                height: 150px;
                margin: 40px 0 !important;
                background-color: #fff;
                border-radius: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: relative;
                @media screen and (max-width: 993px) and (min-width:630px){
                    width: 245px;
                    height: 220px;
                  }
                p{
                  position: absolute;
                  left: 10px;
                  bottom: -10px;
                  background-color: #fff;
                  padding: 2px 10px;
                  border-radius: 20px;
                  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.219);
                }
                  
                  
                @media screen and (max-width: 992px) {
                    margin: 70px 0 0;
                  }
                img{
                    width: 60px;
                    object-fit: contain;
                    
                }
                span{
                    color: #fff;
                    position: absolute;
                    top: 170px;
                    width: 100%;
                    left: 0px;
                    @media screen and (max-width: 993px) and (min-width:630px){
                      top: 240px;
                    }
                }
            }
        }
      }
}