.howMarket {
    background-color: #DCA629;
    padding: 150px 0;
    position: relative;
    overflow: hidden;
    @media (max-width: 992px) {
      padding: 60px 0;
    }
    .title{
      text-align: center;
      @media (max-width: 1000px) {
        width: 100%;
        margin-bottom: 20px;
      }
      h2{
        font-size: 60px;
        font-weight: 700;
        color:#fff;
        margin: 0px 0 70px;
        @media (max-width: 999px) {
          font-size: 44px;
        }
        span{
          color: #521212;
        }
      }
      p{
        font-size: 24px;
        color: rgba(255, 255, 255, 0.7);
        margin: 20px 0;
        text-align: left;
        @media screen and (max-width: 600px){
          font-size: 18px;
          
        }
      }
      
    }
    .block{
        display: flex;
        justify-content: center;
        align-items: center;
      img{
        width: 80%;
        height: 100%;
        @media screen and (max-width: 992px){
         margin: 30px 0;
        }
      }
    }
    .dosDonts{
        width: 100%;
        margin: 50px 0 0;

        .Donts{
        @media only screen and (max-width:700px){
          margin: 50px 0 0px;
        }
      }
      
    
    ul{
        list-style: none;
        padding: 0;
        margin: 30px 0 0;
        li{
          font-size: 24px;
          color: rgba(255, 255, 255, 0.7);
          margin: 15px 0;
          display: flex;
          @media screen and (max-width: 600px){
            font-size: 18px;
            margin: 10px 0;
          }
          img{
            width: 30px;
            height: 30px;
            margin-top: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }