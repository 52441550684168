.brandingServices{
    padding: 150px 0;
    overflow: hidden;
    background-color: #222222;
    @media screen and (max-width: 992px){
        padding: 100px 0;
    }
    @media screen and (max-width: 767px){
        padding: 80px 0;
    }
    .title{
        text-align: center;
        margin-bottom: 70px;
        @media screen and (max-width: 768px){
            margin-bottom: 40px;
        }
        h2{
            font-size: 60px;
            font-weight: 700;
            color:#fff;
            margin-bottom: 10px;

            span{
                color: #DCA629;
                display: block;
            }
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
        }
        p{
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    .block{
        position: relative;
        margin-bottom: 25px;
        border-radius: 25px;
        transition: all 0.4s ease;
        &:hover{
            transform: translateY(-10px);
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, #121212 0%, rgba(18, 18, 18, 0.705) 41%, rgba(18, 18, 18, 0) 100%);
            border-radius: 10px;
            z-index: 1;
            border-radius: 25px;
        }
        img{
            width: 100%;
            height: auto;
            border-radius: 25px;
        }
        h3{
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 0px;
            position: absolute;
            left: 20px;
            bottom: 20px;
            z-index: 99;
            @media screen and (max-width: 992px){
                font-size: 20px;
            }
        }
    }
    .btn-center{
        text-align: center;
        margin-top: 60px;
        @media screen and (max-width: 768px){
            margin-top: 40px;
        }
    }
}