.maintenancePackages{
    padding: 150px 0;
    background: #121212;
    position: relative;
    overflow: hidden;
    z-index: 0;
    @media screen and (max-width: 992px){
        padding: 100px 0;
    }
    @media screen and (max-width: 767px){
        padding: 80px 0;
    }
    .title {
        text-align: center;
    
        h2{
          font-size: 60px;
          font-weight: 700;
          color:#fff;
          margin-bottom: 50px;
          @media (max-width: 992px) {
            font-size: 44px;
          }
          span{
            color: rgba(220, 166, 41, 1);
          }
        }
      }
      .col-md-6{
        &:nth-child(2){
            .maintenancePackages__card{
               background-color: #DDA72A;
               h3,.price{
                color: #521212;
               }
               .description{
                color: rgba(82, 18, 18, 0.7);
               }
            }
        }
      }
      .maintenancePackages__card{
        background: #222222;
        border-radius: 30px;
        padding: 30px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content{
            img{
                width: 72px;
                height: 72px;
            }
            h3{
                font-size: 18px;
                color: #fff;
                margin-bottom: 0px;
            }
            .price{
                font-size: 24px;
                font-weight: 800;
                color: #DDA72A;
                margin-bottom: 0;
            }
            .description{
                font-size: 18px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 0;
                text-align: center;
                margin-top: 25px;
            }
        }

        .btn-center{
            text-align: center;
            margin-top: 30px;
            margin-bottom: 15px;
        }
    }
    
}