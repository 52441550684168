.clientSays{
    padding: 130px 0 30px;
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    @media screen and (max-width: 768px){
        padding: 80px 0 30px;
    }
    .title{
        text-align: center;
        margin-bottom: 50px;
        @media screen and (max-width: 768px){
            margin-bottom: 30px;
        }
        h2{
            font-size: 60px;
            font-weight: 700;
            color:#fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: #dca629;
            }
        }
        p{
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    .swiper_container{
        overflow: hidden;
        position: relative;
        padding-bottom: 165px;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 252px;
            height: 104px;
            background: url(../../../../public/images/home/testimonial.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: -1;
        }
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 944px;
            height: 204px;
            background: url(../../../../public/images/home/testimonialWord.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: -1;
        }
        .swiper-slide{
            .swiper-content{
                img{
                   display: none;
                }
                .content{
                    display: none;
                }
            }   
            &.swiper-slide-active{
                width: 70% !important;
                @media screen and (max-width: 992px){
                   width: 65% !important;                       
                }
                @media screen and (max-width: 600px){
                    width: 100% !important;                       
                 }
                .swiper-content{
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    @media screen and (max-width: 992px){
                        align-items: end;                        
                    }
                    @media screen and (max-width: 600px){
                        width: 100% !important;     
                        flex-direction: column;                  
                     }
                    img{
                        width: 50%;
                        height: 502px;
                        border-left: 8px solid rgba(220, 166, 41, 1);
                        object-fit: cover;
                        display: block;
                        @media screen and (max-width: 992px){
                           height: 400px;                     
                        }
                        @media screen and (max-width: 600px){
                            width: 100% !important;  
                            height: 325px;                     
                         }
                    }
                    .content{
                        width: 50%;
                        display: block;
                        margin-right: 50px;
                        margin-top: 100px;
                        @media screen and (max-width: 992px){
                            margin-right: 10px;                
                         }
                         @media screen and (max-width: 600px){
                            width: 96% !important;     
                            margin: 0 auto 20px;                  
                         }
                        p{
                            color: #fff;
                            font-size: 20px;
                            line-height: 33px;
                            letter-spacing: 1px;
                            @media screen and (max-width: 992px){
                                font-size: 16px;    
                                line-height: 28px;                   
                            }
                        }
                        h2{
                            color: #fff;
                            font-size: 40px;
                            font-weight: 700;
                            margin: 0;
                            @media screen and (max-width: 992px){
                               font-size: 34px;                 
                             }
                        }
                        span{
                            color: #fff;
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
            &.swiper-slide-prev{
                width: 14% !important;
                display: flex;
                align-items: end;
                @media screen and (max-width: 992px){
                    width: 17% !important;                       
                 }
                 @media screen and (max-width: 600px){
                    width: 100% !important;                       
                 }
                .swiper-content{
                    @media screen and (max-width: 600px){
                        width: 100% !important;     
                        flex-direction: column;   
                        display: flex;
                        gap: 20px;
                        align-items: center;               
                     }
                     position: relative;
                     &::after{
                         content: "";
                         position: absolute;
                         top: 0;
                         left: 0;
                         width: 100%;
                         height: 100%;
                         background: #DCA629CC;
                         z-index: 1;
                         @media screen and (max-width: 600px){
                            display: none;
                         }
                     }
                    img{
                        width: 100%;
                        height: 300px;
                        object-fit: cover;
                        display: block;
                        @media screen and (max-width: 600px){
                            width: 100% !important;  
                            height: 325px;    
                            border-left: 8px solid #dca629;
                        object-fit: cover;
                         }
                    }
                    .content{
                        display: none;
                        @media screen and (max-width: 600px){
                            display: block;
                            width: 96% !important;     
                            margin: 0 auto 20px;  
                            p{
                                color: #fff;
                                font-size: 20px;
                                line-height: 33px;
                                letter-spacing: 1px;
                                @media screen and (max-width: 600px){
                                    font-size: 16px;    
                                    line-height: 28px;                   
                                }
                            }
                            h2{
                                color: #fff;
                                font-size: 40px;
                                font-weight: 700;
                                margin: 0;
                                @media screen and (max-width: 600px){
                                   font-size: 34px;                 
                                 }
                            }
                            span{
                                color: #fff;
                                font-size: 14px;
                                font-weight: 400;
                                letter-spacing: 1px;
                            }             
                         }
                    }
                }
            }
            &.swiper-slide-next{
                width: 14% !important;
                display: flex;
                align-items: end;
                @media screen and (max-width: 992px){
                    width: 17% !important;                       
                 }
                 @media screen and (max-width: 600px){
                    width: 100% !important;                       
                 }
                .swiper-content{
                     @media screen and (max-width: 600px){
                        width: 100% !important;     
                        flex-direction: column;    
                        display: flex;
                    gap: 20px;
                    align-items: center;              
                     }
                     position: relative;
                     &::after{
                         content: "";
                         position: absolute;
                         top: 0;
                         left: 0;
                         width: 100%;
                         height: 100%;
                         background: #DCA629CC;
                         z-index: 1;
                         @media screen and (max-width: 600px){
                            display: none;
                         }
                     }
                    img{
                        width: 100%;
                        height: 300px;
                        object-fit: cover;
                        display: block;
                        @media screen and (max-width: 600px){
                            width: 100% !important;  
                            height: 325px;  
                            border-left: 8px solid rgba(220, 166, 41, 1);
                            object-fit: cover;
                         }
                    }
                    .content{
                        display: none;
                        @media screen and (max-width: 600px){
                            display: block;
                            width: 96% !important;     
                            margin: 0 auto 20px;  
                            p{
                                color: #fff;
                                font-size: 20px;
                                line-height: 33px;
                                letter-spacing: 1px;
                                @media screen and (max-width: 600px){
                                    font-size: 16px;    
                                    line-height: 28px;                   
                                }
                            }
                            h2{
                                color: #fff;
                                font-size: 40px;
                                font-weight: 700;
                                margin: 0;
                                @media screen and (max-width: 600px){
                                   font-size: 34px;                 
                                 }
                            }
                            span{
                                color: #fff;
                                font-size: 14px;
                                font-weight: 400;
                                letter-spacing: 1px;
                            }             
                         }
                    }
                }
            }
        }
    }
    .custom-nav-buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -258px;
        padding: 0;
        z-index: 99;
        button{
            background: rgba(220, 166, 41, 1);
            border: none;
            padding: 10px 20px;
            width: 133px;
            height: 58px;
            display: flex;
            align-items: center;
            color: #fff;
            gap: 15px;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.3s;
            @media screen and (max-width: 992px){
               font-size: 16px;  
               width: 120px;
               height: 48px;   
               justify-content: center;                
             }
            &:hover{
                background: rgba(220, 166, 41, 0.927);
            }
        }
        .custom-next{
            color: rgba(11, 11, 11, 1);
            &:hover{
                color: #fff;
                img{
                    filter: invert(1);
                }
            }
        }
        .custom-prev{
            &:hover{
                color: rgba(11, 11, 11, 1);
                img{
                    filter: invert(1);
                }
            }
        }
    }
}