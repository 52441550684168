.ecommercePlatforms{
    padding: 80px 0;
    overflow: hidden;
    @media screen and (max-width: 768px){
      padding: 50px 0;
    }
    .title{
        text-align: center;
        margin-bottom: 70px;
        @media screen and (max-width: 992px){
            margin-bottom: 50px;
        }
        h2{
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: #FFC700;
            }
        }
        p{
            font-size: 24px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 5px;
            @media screen and (max-width: 992px){
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .mainImg{
        img{
            width: 100%;
            height: auto;
            border-radius: 30px;
        }
    }
    .block{
        background: rgba(34, 34, 34, 1);
        padding: 30px;
        border-radius: 20px;
        height: 100%;
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                display: flex;
                align-items: center;
                margin: 0 0 20px;
                img{
                    width: 25px;
                    height: 25px;
                    margin-bottom: 0;
                }
                p{
                    font-size: 30px;
                    color: #fff;
                    margin-bottom: 0;
                    line-height: normal;
                    margin-left: 10px;
                    @media screen and (max-width: 992px){
                        font-size: 18px;
                    }
                }
            }
        }
        p{
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 0;
            line-height: 38px;
            @media screen and (max-width: 992px){
                font-size: 18px;
            }
        }
        img{
            margin-bottom: 20px;
        }
    }
}