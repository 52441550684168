.designedAndDeveloped{
    padding: 100px 0;
    @media screen and (max-width: 992px){
        padding: 50px 0;
    }
    .title{
        max-width: 790px;
        margin: 0 auto 80px;
        text-align: center;
        h2{
            font-size: 60px;
            font-weight: 700;
            color:#fff;
            margin-bottom: 20px;
            @media screen and (max-width: 992px){
                font-size: 44px;
            }
            span{
                color: rgba(220, 166, 41, 1);
                display: block;
            }
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .btn-center{
        text-align: center;
        margin-top: 70px;
        @media screen and (max-width: 768px){
            margin-top: 40px;
        }
    }
}